import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormInputNumber = ({
  name,
  control,
  label,
  placeholder,
  iconLeft,
  config = {},
  isFloat = false,
  hasComma = true,
  disabled,
}: FormInputProps) => {
  const normalizeNumber = (value: number) => {
    const options = { minimumFractionDigits: 2 };
    const normalizedInt = new Intl.NumberFormat('en').format(value);
    const normalizedFloat = new Intl.NumberFormat('en', options).format(
      value / 100
    );
    return isFloat ? normalizedFloat : normalizedInt;
  };

  const parseValue = (value: string) => {
    const sanitizedValue = value ? value.toString().replace(/\D/g, '') : '0';
    return isFloat ? parseInt(sanitizedValue) / 100 : parseInt(sanitizedValue);
  };

  const seValue = (value: any) => {
    const newValue = isFloat ? value * 100 : value;
    return hasComma ? normalizeNumber(newValue) : newValue;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={config}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <>
          <TextField
            helperText={error ? error.message : null}
            size="medium"
            error={!!error}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChange(parseValue(event.target.value));
            }}
            onBlur={onBlur}
            value={value ? seValue(value) : ''}
            fullWidth
            label={label}
            placeholder={placeholder}
            InputProps={{
              startAdornment: iconLeft ? (
                <InputAdornment position="start">
                  <Icon>{iconLeft}</Icon>
                </InputAdornment>
              ) : null,
            }}
            disabled={disabled}
            sx={{ mt: 2 }}
            data-testid={name}
            inputProps={{
              'data-testid': `${name}-input`,
            }}
          />
        </>
      )}
    />
  );
};
