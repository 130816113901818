import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormInputTextArea = ({
  name,
  control,
  label,
  placeholder,
  iconLeft,
  config = {},
  disabled,
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={config}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
        formState,
      }) => (
        <TextField
          helperText={error ? error.message : null}
          size="medium"
          error={!!error}
          onChange={onChange}
          onBlur={onBlur}
          value={value || ''}
          fullWidth
          label={label}
          placeholder={placeholder}
          InputProps={{
            startAdornment: iconLeft ? (
              <InputAdornment position="start">
                <Icon>{iconLeft}</Icon>
              </InputAdornment>
            ) : null,
          }}
          InputLabelProps={{ shrink: true }}
          multiline
          disabled={disabled}
          data-testid={name}
          inputProps={{
            'data-testid': `${name}-input`,
          }}
        />
      )}
    />
  );
};
