import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormInputSelect: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  options = [],
  config = {},
}) => {
  const generateSingleOptions = () => {
    return options.map((option: any) => {
      return (
        <MenuItem
          key={option.id}
          value={option.id}
          data-testid={`${name}-${option.id}`}
        >
          {option.label}
        </MenuItem>
      );
    });
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select
            onChange={onChange}
            value={value || ''}
            data-testid={name}
            autoWidth
          >
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
        rules={config}
      />
    </FormControl>
  );
};
