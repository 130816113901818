'use client';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useRef, useState } from 'react';

type Props = {
  content: React.ReactNode;
  footer: React.ReactNode;
  isScrollable?: boolean;
  maxWidth?: boolean;
};

function FormLayout({ content, footer, isScrollable, maxWidth = true }: Props) {
  const theme = useTheme();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [isContentScrollable, setIsContentScrollable] = useState(false);

  // effect runs when content is updated, then computes for scrollable content
  useEffect(() => {
    if (contentRef.current && containerRef.current) {
      const contentHeight = contentRef.current.scrollHeight;
      const containerHeight = containerRef.current.clientHeight;
      setIsContentScrollable(contentHeight > containerHeight);
    }
  }, [content]);

  return (
    <Stack sx={{ height: '100%', gap: 1 }}>
      <Stack
        sx={{
          flexGrow: 1,
          height: '100%',
          overflowY: { xs: 'scroll' },
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            margin: '0 32px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.divider,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.main,
          },
          justifyContent:
            isScrollable || isContentScrollable ? 'flex-start' : 'center',
          alignItems: 'center',
        }}
        className="form-container"
        ref={containerRef}
      >
        <Stack
          sx={{
            p: 2,
            width: '100%',
            maxWidth: { sm: '100%', md: maxWidth ? '400px' : '100%' },
            height: 'fit-content',
          }}
        >
          <Stack className="form-content" ref={contentRef}>
            {content}
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', alignItems: 'center' }}>
        <Stack sx={{ width: '100%', maxWidth: { sm: '100%', md: '400px' } }}>
          {footer}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default FormLayout;
