import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      response
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      response
    }
  }
`;

export const SIGN_PRIVACY_POLICY = gql`
  mutation signPrivacyPolicy($input: SignPrivacyPolicyInput!) {
    signPrivacyPolicy(input: $input) {
      response
    }
  }
`;

export const ACCEPT_COMPANY_INVITE = gql`
  mutation acceptCompanyInvite($input: AcceptCompanyInviteInput!) {
    acceptCompanyInvite(input: $input) {
      response
    }
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation createTransaction($input: CreateTransactionInput!) {
    createTransaction(input: $input) {
      response
    }
  }
`;

export const CREATE_TRANSACTION_AND_ACCEPT_COMPANY_INVITE = gql`
  mutation createTransactionAndAcceptCompanyInvite(
    $createTransactionInput: CreateTransactionInput!
    $acceptCompanyInviteInput: AcceptCompanyInviteInput!
  ) {
    createTransaction(input: $createTransactionInput) {
      response
    }
    acceptCompanyInvite(input: $acceptCompanyInviteInput) {
      response
    }
  }
`;

export const ADD_COMPANY_TO_TRANSACTION = gql`
  mutation addCompanyToTransaction($input: AddCompanyToTransactionInput!) {
    addCompanyToTransaction(input: $input) {
      response
    }
  }
`;

export const SUBMIT_FORM = gql`
  mutation submitForm($input: SubmitFormInput!) {
    submitForm(input: $input) {
      response
    }
  }
`;

export const SUBMIT_DYNAMIC_CUSTOMER_FORM = gql`
  mutation submitDynamicCustomerForm($input: SubmitDynamicCustomerFormInput!) {
    submitDynamicCustomerForm(input: $input) {
      response
    }
  }
`;

export const SUBMIT_DYNAMIC_TRANSACTION_FORM = gql`
  mutation submitDynamicTransactionForm(
    $input: SubmitDynamicTransactionFormInput!
  ) {
    submitDynamicTransactionForm(input: $input) {
      response
    }
  }
`;

export const START_VERIFY_ID = gql`
  mutation startVerifyId($input: StartVerifyIdInput!) {
    startVerifyId(input: $input) {
      link
      requestId
    }
  }
`;

export const SIGN_CONTRACT = gql`
  mutation signContract($input: SignContractInput!) {
    signContract(input: $input) {
      response
    }
  }
`;

export const ADD_PARTICIPANT_TO_TRANSACTION = gql`
  mutation addParticipantToTransaction(
    $input: AddParticipantToTransactionInput!
  ) {
    addParticipantToTransaction(input: $input) {
      response
    }
  }
`;

export const REMOVE_PARTICIPANT_FROM_TRANSACTION = gql`
  mutation removeParticipantFromTransaction(
    $input: RemoveParticipantFromTransactionInput!
  ) {
    removeParticipantFromTransaction(input: $input) {
      response
    }
  }
`;

export const SEND_PARTICIPANT_INVITE_EMAIL = gql`
  mutation sendParticipantInviteEmail(
    $input: SendParticipantInviteEmailInput!
  ) {
    sendParticipantInviteEmail(input: $input) {
      response
    }
  }
`;

export const ADD_USER_TO_PARTICIPANT = gql`
  mutation addUserToParticipant($input: AddUserToParticipantInput!) {
    addUserToParticipant(input: $input) {
      response
    }
  }
`;

export const CUSTOMER_IDENTITY_UPDATE = gql`
  mutation customerIdentityUpdate($input: CustomerIdentityUpdateInput!) {
    customerIdentityUpdate(input: $input) {
      response
    }
  }
`;

export const CONFIRM_OFFER = gql`
  mutation confirmOffer($input: ConfirmOfferInput!) {
    confirmOffer(input: $input) {
      response
    }
  }
`;

export const CREATE_TRANSACTION_WITH_PARTICIPANTS = gql`
  mutation createTransactionWithParticipants(
    $input: CreateTransactionWithParticipantsInput!
  ) {
    createTransactionWithParticipants(input: $input) {
      response
    }
  }
`;

export const ADD_BUYING_ESTATE_AGENT_FROM_INVITE = gql`
  mutation addBuyingEstateAgentFromInvite(
    $input: AddBuyingEstateAgentFromInviteInput!
  ) {
    addBuyingEstateAgentFromInvite(input: $input) {
      response
    }
  }
`;

export const ADD_SELLING_ESTATE_AGENT_FROM_INVITE = gql`
  mutation addSellingEstateAgentFromInvite(
    $input: AddSellingEstateAgentFromInviteInput!
  ) {
    addSellingEstateAgentFromInvite(input: $input) {
      response
    }
  }
`;

export const ADD_INFORMATION_TO_TRANSACTION_PARTICIPANT = gql`
  mutation addInformationToTransactionParticipant(
    $input: AddInformationToTransactionParticipantInput!
  ) {
    addInformationToTransactionParticipant(input: $input) {
      response
    }
  }
`;

export const SELECT_TITLES = gql`
  mutation selectTitles($input: SelectTitlesInput!) {
    selectTitles(input: $input) {
      response
    }
  }
`;

export const PUBLISH_PROPERTY_PACK = gql`
  mutation propertyPublish($input: PropertyPublishInput!) {
    propertyPublish(input: $input) {
      status {
        status
        validation {
          error
          status
          overlay
        }
      }
    }
  }
`;

export const DOCUMENT_UPLOAD_REQUEST = gql`
  mutation documentUploadRequest($input: DocumentUploadRequestInput!) {
    documentUploadRequest(input: $input) {
      document {
        fileName
        domain
        key
        displayName
        description
        mimeType
        documentType
        presignedUrl
        ownerId
        ownerName
        documentId
      }
    }
  }
`;

export const DOCUMENT_UPLOAD_COMPLETE = gql`
  mutation documentUploadComplete($input: DocumentUploadCompleteInput!) {
    documentUploadComplete(input: $input) {
      document {
        fileName
        domain
        key
        displayName
        description
        mimeType
        documentType
        ownerId
        ownerName
        presignedUrl
        file
        documentId
      }
    }
  }
`;

export const COMPLETE_TASK = gql`
  mutation completeTask($input: CompleteTaskInput!) {
    completeTask(input: $input) {
      task {
        taskId
      }
    }
  }
`;

export const START_CHECKOUT_SESSION = gql`
  mutation startCheckoutSession($input: StartCheckoutSessionInput!) {
    startCheckoutSession(input: $input) {
      link
    }
  }
`;

export const START_TRANSACTION_DOCUMENT = gql`
  mutation startTransactionDocument($input: StartTransactionDocumentInput!) {
    startTransactionDocument(input: $input) {
      link
    }
  }
`;

export const DOCUMENT_CHANGE_STATE = gql`
  mutation documentChangeState($input: DocumentChangeStateInput!) {
    documentChangeState(input: $input) {
      document {
        domain
        subDomain
        key
        status
        fileName
        displayName
        description
        mimeType
        documentType
        ownerId
        ownerName
        presignedUrl
      }
    }
  }
`;

export const ADD_TRANSACTION_FUNDS = gql`
  mutation addTransactionFunds($input: AddTransactionFundsInput!) {
    addTransactionFunds(input: $input) {
      funds {
        transactionFundId
        transactionId
        created
        deleted
        type
        subtype
        amount
        userName
        userId
        detail
        rate
        evidenceType
        evidenceRef
      }
    }
  }
`;

export const DELETE_TRANSACTION_FUNDS = gql`
  mutation deleteTransactionFunds($input: DeleteTransactionFundsInput!) {
    deleteTransactionFunds(input: $input) {
      funds {
        transactionFundId
        transactionId
        created
        deleted
        type
        subtype
        amount
        userName
        userId
        detail
        rate
        evidenceType
        evidenceRef
      }
    }
  }
`;

export const OFFER_SUBSTANTIATION_SUBMIT = gql`
  mutation offerSubstantiationSubmit($input: OfferSubstantiationSubmitInput!) {
    offerSubstantiationSubmit(input: $input) {
      offer {
        offerId
        companySlug
        amount
        status
        buyingTransactionId
        address
        substantiations {
          offerSubstantiationId
          status
          userId
          userName
          created
          totalAmount
          funds {
            type
            subtype
            amount
            rate
            evidenceType
            evidenceRef
            created
            userName
            userId
          }
        }
      }
    }
  }
`;

export const LOCK_CASE = gql`
  mutation lockCase($input: LockCaseInput!) {
    lockCase(input: $input) {
      response
    }
  }
`;

export const ADD_USER_TO_PARTICIPANT_BY_EMAIL = gql`
  mutation addUserToParticipantByEmail(
    $input: AddUserToParticipantByEmailInput!
  ) {
    addUserToParticipantByEmail(input: $input) {
      response
    }
  }
`;

export const CONNECT_TRANSACTION = gql`
  mutation connectTransaction($input: ConnectTransactionInput!) {
    connectTransaction(input: $input) {
      case {
        caseId
      }
    }
  }
`;
