import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormInputRadio: React.FC<FormInputProps> = ({
  name,
  control,
  label,
  options = [],
  config = {},
}) => {
  const generateRadioOptions = () => {
    return options.map((singleOption) => (
      <FormControlLabel
        key={singleOption.id}
        value={singleOption.id}
        label={singleOption.label}
        control={<Radio data-testid={`${name}-${singleOption.id}`} />}
      />
    ));
  };

  return (
    <FormControl component="fieldset">
      <Controller
        name={name}
        control={control}
        rules={config}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <>
            <RadioGroup
              value={value || ''}
              onChange={onChange}
              data-testid={name}
              sx={{ pl: 2 }}
            >
              {generateRadioOptions()}
            </RadioGroup>
            {error && (
              <FormHelperText error={!!error}>{error.message}</FormHelperText>
            )}
          </>
        )}
      />
    </FormControl>
  );
};
