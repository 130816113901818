import ArrowBack from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useForm } from 'react-hook-form';
import { DynamicFooterData } from './FormTypes';

export const FormFooterComponents = ({
  name,
  type,
  label,
  rank,
  url,
  target,
  iconRight,
  iconLeft,
  onBack,
  onSkip,
  onClose,
  onRedirect,
}: DynamicFooterData) => {
  const {
    formState: { isSubmitting },
  } = useForm();

  const handleRedirect = () => {
    if (onRedirect && type == 'redirect') {
      const redirectUrl = url === null ? '/' : url;
      onRedirect(redirectUrl, target);
    }
  };

  switch (type) {
    case 'back':
      return (
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          onClick={onBack}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="back"
        >
          {label}
        </Button>
      );
    case 'next':
      return (
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          type="submit"
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="next"
        >
          {label}
        </Button>
      );
    case 'backNext':
      return (
        <Stack direction="row" spacing={1}>
          <IconButton
            color="secondary"
            size="large"
            onClick={onBack}
            data-testid="back"
          >
            <ArrowBack />
          </IconButton>
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            startIcon={<Icon>{iconLeft}</Icon>}
            endIcon={<Icon>{iconRight}</Icon>}
            data-testid="next"
          >
            {label}
          </Button>
        </Stack>
      );
    case 'submit':
      return (
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{ width: '100%' }}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="submit"
        >
          {label}
        </Button>
      );
    case 'backSubmit':
      return (
        <Stack direction="row" spacing={1}>
          <IconButton
            color="secondary"
            size="large"
            onClick={onBack}
            data-testid="back"
          >
            <ArrowBack />
          </IconButton>
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{ width: '100%' }}
            startIcon={<Icon>{iconLeft}</Icon>}
            endIcon={<Icon>{iconRight}</Icon>}
            data-testid="submit"
          >
            {label}
          </Button>
        </Stack>
      );
    case 'skip':
      return (
        <Button
          variant="contained"
          size="large"
          onClick={onSkip}
          sx={{ width: '100%' }}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="skip"
        >
          {label}
        </Button>
      );
    case 'close':
      return (
        <Button
          variant="contained"
          size="large"
          onClick={onClose}
          sx={{ width: '100%' }}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="close"
        >
          {label}
        </Button>
      );
    case 'redirect':
      return (
        <Button
          variant="contained"
          size="large"
          onClick={handleRedirect}
          sx={{ width: '100%' }}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
          data-testid="redirect"
        >
          {label}
        </Button>
      );
    default:
      return (
        <Button
          variant="contained"
          size="large"
          sx={{ width: '100%' }}
          startIcon={<Icon>{iconLeft}</Icon>}
          endIcon={<Icon>{iconRight}</Icon>}
        >
          {label}
        </Button>
      );
  }
};
