import { useFormContext } from 'react-hook-form';
import { DynamicFieldData } from './FormTypes';
import { FormInputDate } from './fields/FormInputDate';
import { FormInputEmail } from './fields/FormInputEmail';
import { FormInputMultiCheckbox } from './fields/FormInputMultiCheckbox';
import { FormInputNumber } from './fields/FormInputNumber';
import { FormInputPhoneInternational } from './fields/FormInputPhoneInternational';
import { FormInputRadio } from './fields/FormInputRadio';
import { FormInputSelect } from './fields/FormInputSelect';
import { FormInputText } from './fields/FormInputText';
import { FormInputTextArea } from './fields/FormInputTextArea';
import { FormSwitch } from './fields/FormSwitch';

/**
 * Internal utility function to flatten the errors object
 */
const flattenObject = (obj: any, parentKey?: string) => {
  let result = {};

  Object.keys(obj).forEach((key) => {
    if (key === 'ref') return; // skip ref key as RHF appends this ref object iniside the error object

    const value = obj[key];
    const _key = parentKey ? parentKey + '.' + key : key;
    if (typeof value === 'object') {
      if (key === 'message') return obj;
      result = { ...result, ...flattenObject(value, _key) };
    } else {
      // @ts-ignore
      result[_key] = value;
    }
  });

  return result;
};

export const FormFields = ({
  type,
  name,
  label,
  default_value,
  disabled,
  placeholder,
  iconLeft,
  options = [],
  config = {},
  submitFormat,
}: DynamicFieldData) => {
  const {
    register,
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const flattenedErrors: { [key: string]: any } = flattenObject(errors);
  const fieldError = { message: flattenedErrors[`${name}.message`] };
  const parsedConfig = typeof config === 'string' ? JSON.parse(config) : config;

  // check if config has pattern if so, convert pattern json string to regex
  const fieldPattern = parsedConfig?.pattern && {
    value: new RegExp(parsedConfig.pattern.value),
    message: parsedConfig.pattern.message,
  };

  const fieldConfig = { ...parsedConfig, pattern: fieldPattern };

  switch (type) {
    case 'text':
      return (
        <FormInputText
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'textArea':
      return (
        <FormInputTextArea
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'number':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'integer':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          hasComma={false}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'float':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          isFloat={true}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'money':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          isFloat={false}
          iconLeft="currency_pound"
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'moneyFloat':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          isFloat={true}
          iconLeft="currency_pound"
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'phone':
      return (
        <FormInputPhoneInternational
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          iconLeft="phone_iphone"
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'email':
      return (
        <FormInputEmail
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          iconLeft="mail"
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'select':
      return (
        <FormInputSelect
          name={name}
          control={control}
          label={label}
          options={options}
          config={fieldConfig}
        />
      );
    case 'radio':
      return (
        <FormInputRadio
          name={name}
          control={control}
          label={label}
          options={options}
          config={fieldConfig}
        />
      );
    case 'checkbox':
      return (
        <FormInputMultiCheckbox
          name={name}
          control={control}
          label={label}
          setValue={setValue}
          options={options}
          config={fieldConfig}
        />
      );
    case 'date':
      return (
        <FormInputDate
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          config={fieldConfig}
          submitFormat={submitFormat}
        />
      );
    case 'percentage':
      return (
        <FormInputNumber
          name={name}
          control={control}
          label={label}
          placeholder={placeholder}
          isFloat={true}
          iconLeft={iconLeft}
          config={fieldConfig}
          disabled={disabled}
        />
      );
    case 'switch':
      return (
        <FormSwitch
          name={name}
          control={control}
          label={label}
          config={fieldConfig}
        />
      );
    case 'hidden':
      return (
        <input
          type="text"
          name={name}
          placeholder={placeholder}
          {...register}
          hidden
        />
      );
    default:
      return (
        <div>
          <h3>{label}</h3>
          <input {...register} />
        </div>
      );
  }
};
