import { Icon, InputAdornment } from '@mui/material';
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

/**
 * Implemented using mui-tel-input package
 * API reference: viclafouch.github.io/mui-tel-input/docs/api-reference/
 */
export const FormInputPhoneInternational = ({
  name,
  control,
  label,
  placeholder,
  iconLeft,
  config = {},
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...config,
        validate: (value) => {
          if (value)
            return (
              matchIsValidTel(value) ||
              `Phone number must be in international format (e.g. +447712345678)`
            );
        },
      }}
      render={({
        field: { ref: fieldRef, onChange, onBlur, value, ...fieldProps },
        fieldState: { error },
      }) => (
        <MuiTelInput
          {...fieldProps}
          helperText={error ? error.message : null}
          error={!!error}
          value={value || ''}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          placeholder={placeholder}
          InputProps={{
            endAdornment: iconLeft ? (
              <InputAdornment position="start">
                <Icon>{iconLeft}</Icon>
              </InputAdornment>
            ) : null,
          }}
          fullWidth
          inputRef={fieldRef}
          defaultCountry="GB"
          preferredCountries={['GB', 'US']}
          disableFormatting
          forceCallingCode
          data-testid={name}
          inputProps={{
            'data-testid': `${name}-input`,
          }}
        />
      )}
    />
  );
};
