'use client';

import FormLayout from '@/components/_layout/Form/Form';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormFields } from './FormFields';
import { FormFooterComponents } from './FormFooterComponents';
import { DynamicFooterComponent, DynamicFormComponent } from './FormTypes';
import styles from './form.module.scss';

interface FormProps {
  /**
   * array of field objects used to generate the form with type of DynamicFormComponent
   * check sample json schema/data for reference can be found in ./formFieldsSampleData.ts
   */
  components: DynamicFormComponent[];
  /**
   * array of footer objects used to generate the form with type of DynamicFormComponent
   * check sample json schema/data for reference can be found in ./formFieldsSampleData.ts
   */
  footerComponents: DynamicFooterComponent[];
  /**
   * callback function that gets triggered when the form is a valid form and submit happens
   */
  onSubmit: (data: any) => void;
  /**
   * callback function that gets triggered when Back button in clicked
   */
  onBack?: () => void;
  /**
   * callback function that gets triggered when Skip button in clicked
   */
  onSkip?: () => void;
  /**
   * callback function that gets triggered when Close button in clicked
   */
  onClose?: () => void;
  /**
   * callback function that gets triggered when Redirect button in clicked
   * @param url url to redirect to
   * @param target internal or external url
   */
  onRedirect?: (url: any, target: any) => void;
  /**
   * optional default values for the form
   */
  defaultValues?: any;
  /**
   * boolean to check if the form has a floating widget for footer or not
   */
  isFloatingWidget?: boolean;
  /**
   * Determines the colour scheme of flaoting widget
   */
  floatingWidgetRank?: string | null;
  /**
   * boolean to show form submit loading state until external submit handler is resolved
   */
  isLoading?: boolean;
  /**
   * current form values of wizard
   */
  formValues?: any;
}

export const Form = ({
  components,
  footerComponents,
  onSubmit,
  onBack,
  onSkip,
  onClose,
  onRedirect,
  isFloatingWidget = false,
  floatingWidgetRank = 'secondary',
  defaultValues = null,
  isLoading = false,
  formValues,
}: FormProps) => {
  // exposes the form methods to the form
  const formMethods = useForm({
    mode: 'onTouched',
    defaultValues: defaultValues,
  });

  // effect runs when default values are updated, when not set it defaults to null
  useEffect(() => {
    // resets the form with default values
    formMethods.reset({ ...defaultValues });
  }, [defaultValues]);

  // effect runs on init and gets the form values as default values
  useEffect(() => {
    formMethods.reset(formValues);
  }, []);

  const generateGuidance = (text: any) => {
    return text;
    // eslint-disable-next-line react/no-children-prop
    // return text ? <ReactMarkdown children={text} /> : null;
  };

  const generateComponent = (component: any) => {
    // checks if the component has a component trigger input
    if (component?.componentTrigger?.length > 0) {
      /**
       * if the component trigger input value matches the current value of the specified input
       * show the component, if not do not show
       * this utilizes the RHF's watch method to watch the value of the specified input and compare it with the component trigger input value
       * https://react-hook-form.com/api/useform/watch
       * NOTE: for now it only supports one component trigger
       */
      return (
        <>
          {component.componentTrigger[0].value.includes(
            formMethods.watch(component.componentTrigger[0].input)
          ) && (
            <Stack key={component.componentId} sx={{ gap: 1 }}>
              <Stack key={component.componentId} sx={{ gap: 1 }}>
                <Typography variant="h6">{component.componentLabel}</Typography>
                <Typography variant="body1">
                  {generateGuidance(component.componentGuidance)}
                </Typography>
              </Stack>
              <Stack key={component.componentId} sx={{ gap: 1 }}>
                {component.componentInputs.map(
                  (field: any, fieldIndex: number) => (
                    <FormFields
                      key={field.name + fieldIndex}
                      {...field}
                      default_value={null}
                    />
                  )
                )}
              </Stack>
            </Stack>
          )}
        </>
      );
    } else {
      // if the component does not have a component trigger input render as normal
      return (
        <Stack key={component.componentId} sx={{ gap: 1 }}>
          <Stack key={component.componentId} sx={{ gap: 1, px: 1 }}>
            <Typography variant="h6">{component.componentLabel}</Typography>
            <Typography variant="body1">
              {generateGuidance(component.componentGuidance)}
            </Typography>
          </Stack>
          <Stack key={component.componentId} sx={{ gap: 1 }}>
            {component.componentInputs.map((field: any, fieldIndex: number) => (
              <FormFields key={field.name + fieldIndex} {...field} />
            ))}
          </Stack>
        </Stack>
      );
    }
  };

  const generateFooterComponent = (component: any) => {
    return (
      <>
        {component.componentInputs.map((footer: any, footerIndex: number) => (
          <Stack
            key={footer.name + footerIndex}
            className={styles.footerComponent}
            sx={{ gap: 1 }}
          >
            <FormFooterComponents
              {...footer}
              onBack={onBack}
              onSkip={onSkip}
              onClose={onClose}
              onRedirect={onRedirect}
            />
          </Stack>
        ))}
      </>
    );
  };

  const Content = (
    <Stack gap={4}>
      {components.map((component) => generateComponent(component))}
    </Stack>
  );

  const Footer = (
    <>
      {footerComponents.map((component) => generateFooterComponent(component))}
    </>
  );

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ height: '100%', flexGrow: 1 }}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <FormLayout content={Content} footer={Footer}></FormLayout>
      </form>
    </FormProvider>
  );
};
