import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormSwitch = ({
  name,
  control,
  label,
  config = {},
}: FormInputProps) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={config}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <FormControlLabel
            control={
              <Switch
                color="success"
                checked={value || false}
                onChange={onChange}
              />
            }
            label={label}
            labelPlacement="start"
            data-testid={name}
            sx={{ justifyContent: 'space-between', margin: 0 }}
          />
          <FormHelperText>{error ? error.message : null}</FormHelperText>
        </>
      )}
    />
  );
};
