import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

export const FormInputMultiCheckbox: React.FC<FormInputProps> = ({
  name,
  control,
  setValue,
  label,
  options = [],
  config = {},
}) => {
  const [selectedItems, setSelectedItems] = useState<any>([]);
  // we are handling the selection manually here
  const handleSelect = (id: any) => {
    const isPresent = selectedItems.indexOf(id);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item: any) => item !== id);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems: any) => [...prevItems, id]);
    }
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems);
  }, [name, selectedItems, setValue]);

  return (
    <FormControl size={'small'} variant={'outlined'}>
      <Stack>
        {options.map((option: any) => {
          return (
            <FormControlLabel
              control={
                <Controller
                  name={name}
                  rules={config}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        checked={selectedItems.includes(option.id)}
                        onChange={() => handleSelect(option.id)}
                        data-testid={`${name}-${option.id}`}
                        sx={{ pl: 2 }}
                      />
                    );
                  }}
                  control={control}
                />
              }
              label={option.label}
              key={option.id}
              data-testid={name}
            />
          );
        })}
      </Stack>
    </FormControl>
  );
};
