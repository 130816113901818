import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';
import { FormInputProps } from '../FormTypes';

const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';

export const FormInputDate = ({
  name,
  control,
  label,
  placeholder,
  config,
  submitFormat,
}: FormInputProps) => {
  const dateSubmitFormat = submitFormat || DEFAULT_DATE_FORMAT;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        rules={config}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <>
            <DatePicker
              value={value ? dayjs(value, dateSubmitFormat) : null}
              onChange={(date: any) => {
                onChange(date.format(dateSubmitFormat));
              }}
              format={DEFAULT_DATE_FORMAT}
              slotProps={{
                textField: {
                  label: label,
                  placeholder: placeholder,
                  error: !!error,
                  helperText: error ? error.message : null,
                  inputProps: {
                    'data-testid': `${name}-input`,
                  },
                },
              }}
              data-testid={name}
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
};
